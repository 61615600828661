<template>
  <div>
    <SkeletonTable
        :fields="fields"
        :rows="10"
        :table-props="{ borderless: true, striped: true, headVariant: 'light' }"
    />
  </div>
</template>

<script>
import SkeletonTable from "@/components/SkeletonTable.js";

export default {
  data() {
    return {
      fields: [
        {name: "Nom", skeletonProps: {width: "70%"}},
        {name: "", skeletonProps: {type: "button", width: "50%"}},
      ],
    };
  },
  components: {
    SkeletonTable,
  },
};
</script>

