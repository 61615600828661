<template>
  <div>
    <b-link :to="{ name: 'addPost' }">Ajouter un poste</b-link>
    <b-row>
      <b-col>
        <b-form-input v-model="searchedName" placeholder="Rechercher par nom"/>
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <PostListSkeleton/>
          </template>
          <PostList :posts="filteredPosts"/>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";
import PostList from "@/components/posts/PostList";
import PostListSkeleton from "@/components/posts/PostListSkeleton";

export default {
  components: {
    PostList,
    PostListSkeleton,
  },
  data() {
    return {
      loading: true,
      searchedName: null,
    };
  },
  computed: {
    ...mapState({
      posts: (state) => state.posts.all,
    }),
    filteredPosts() {
      if (!this.searchedName) {
        return this.posts;
      }

      const search = this.searchedName.toLowerCase();
      return this.posts.filter((post) => {
        return post.name.toLowerCase().indexOf(search) !== -1;
      });
    },
  },
  async created() {
    try {
      await this.$store.dispatch("posts/fetchPosts");
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title: "Une erreur est survenue lors de la récupération des postes",
        text: e.message,
      });
      return;
    }
    this.loading = false;
  },
};
</script>

