<template>
  <b-table
      hover
      striped
      borderless
      head-variant="light"
      :items="sortedPosts"
      :fields="fields"
  >
    <template #cell(name)="post">{{ post.item.name }}</template>
    <template #cell(edit)="post">
      <div class="row justify-content-end mr-0">
        <EditDeleteButtonGroup
            @edit="editPost(post.item)"
            @delete="deletePost(post.item)"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import EditDeleteButtonGroup from "@/components/buttons/EditDeleteButtonGroup";

export default {
  data() {
    return {
      fields: [
        {key: "name", label: "Nom"},
        {key: "edit", label: ""},
      ],
    };
  },
  props: {
    posts: {
      required: true,
      type: Array,
    },
  },
  computed: {
    sortedPosts() {
      return this.posts
          .slice()
          .sort((a, b) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
          );
    },
  },
  components: {
    EditDeleteButtonGroup,
  },
  methods: {
    editPost(post) {
      this.$router.push({
        name: "post",
        params: {uid: post.uid},
      });
    },
    async deletePost(post) {
      try {
        await this.$store.dispatch("posts/deletePost", post.uid);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la suppression du poste",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Poste supprimé",
      });
    },
  },
};
</script>

